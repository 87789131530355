import React, { useEffect, useRef, useState } from "react";
import "../../assets/scss/pages/flatpickr.scss";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Button,
  Input,
  Label,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import Multiselect from "multiselect-react-dropdown";
import { Space, Table } from "antd";
import { GetProcessLogsList } from "../../services/Processlogs/ProcessLogsServices";
import { errornotify } from "../../components/Common/notification";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import Flatpickr from "react-flatpickr";
import UseAuth from "../../components/UseAuth";
const Processlogs = () => {
  UseAuth();
  /**This is for show hide handller */
  const [showHide, setShowHide] = useState(true);
  const [dataSoucre, setDataSource] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState([]);
  const searchInput = useRef(null);
  // console.log("filterDate", filterDate);
  /**This is for secondary rows */
  const showHideHandller = () => {
    setShowHide(!showHide);
  };

  /**This function for call process logs api services */
  const processLogsFunction = async () => {
    try {
      let params = new URLSearchParams();
      debugger;
      let fromDateFormate = new Date(fromDate);
      let toDateFormate = new Date(toDate);
      params.append(
        "fromDate",
        `${fromDateFormate.getFullYear()},${
          fromDateFormate.getMonth() + 1
        },${fromDateFormate.getDate()}`
      );
      params.append(
        "toDate",
        `${toDateFormate.getFullYear()},${
          toDateFormate.getMonth() + 1
        },${toDateFormate.getDate()}`
      );

      const response = await GetProcessLogsList(params);
      if (response?.data?.status === "SUCCESS") {
        setDataSource(response.data.data);
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  useEffect(() => {
    processLogsFunction();
  }, [fromDate, toDate]);

  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  /**This function for datepicker handller */
  const onChangeDatePickerHandller = (selectedDates) => {
    setFromDate(selectedDates[0]);
    if (selectedDates?.length > 1) {
      setToDate(selectedDates[1]);
    }
  };
  console.log(selectedDates);
  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      sorter: {
        compare: (a, b) => a.severity.localeCompare(b.severity),
        multiple: 6,
      },
      filters: [
        {
          text: "INFO",
          value: "INFO",
        },
        {
          text: "ERROR",
          value: "ERROR",
        },
      ],
      onFilter: (value, record) => record.severity === value,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      sorter: {
        compare: (a, b) => a.source.localeCompare(b.source),
        multiple: 5,
      },
      ...getColumnSearchProps("source"),
    },
    {
      title: "Parameters",
      dataIndex: "parameters",
      key: "parameters",
      sorter: {
        compare: (a, b) => a.parameters.localeCompare(b.parameters),
        multiple: 4,
      },
      ...getColumnSearchProps("parameters"),
      render: (text, record) => {
        return (
          <>
            <UncontrolledTooltip
              placement="top"
              target={`tooltipId${record?.processLogId}`}
            >
              {record.parameters}
            </UncontrolledTooltip>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
              id={`tooltipId${record?.processLogId}`}
            >
              {record.parameters}
            </p>
          </>
        );
      },
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      ...getColumnSearchProps("start_time"),
      sorter: {
        compare: (a, b) => a.start_time.localeCompare(b.start_time),
        multiple: 3,
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      ...getColumnSearchProps("end_time"),
      sorter: {
        compare: (a, b) => a.end_time.localeCompare(b.end_time),
        multiple: 2,
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
      sorter: {
        compare: (a, b) => a.username.localeCompare(b.username),
        multiple: 1,
      },
    },
  ];
  /**This is for children data*/
  const expandedRowRender = (row) => {
    const dataSource = [
      {
        log_time: row.log_time,
        elapsed_secs: row.elapsed_secs,
        rows_affected: row.rows_affected,
        ErrorLogId: row.errorLogId,
        ErrorLine: row.errorLine,
        ErrorProcedure: row.errorProcedure,
        ErrorMessage: row.errorMessage,
        ErrorDateTime: row.errorDateTime,
      },
    ];
    const columns = [
      {
        title: "Log time",
        dataIndex: "log_time",
      },
      {
        title: "Elapsed Secs",
        dataIndex: "elapsed_secs",
      },
      {
        title: "Rows Affected",
        dataIndex: "rows_affected",
      },
      {
        title: "Error LogId",
        dataIndex: "ErrorLogId",
      },
      {
        title: "ErrorLine",
        dataIndex: "ErrorLine",
      },
      {
        title: "Error Procedure",
        dataIndex: "ErrorProcedure",
      },
      {
        title: "Error Message",
        dataIndex: "ErrorMessage",
        render: (text, record) => {
          return (
            <>
              <UncontrolledTooltip
                placement="top"
                target={`tooltipId${record?.processLogId}secondary`}
              >
                {record.ErrorMessage}
              </UncontrolledTooltip>
              <p
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "150px",
                }}
                id={`tooltipId${record?.processLogId}secondary`}
              >
                {record.ErrorMessage}
              </p>
            </>
          );
        },
      },
      {
        title: "Error DateTime",
        dataIndex: "ErrorDateTime",
      },
    ];
    return (
      <Table pagination={false} columns={columns} dataSource={dataSource} />
    );
  };
  /**This is for input text handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSoucre.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
    } else {
      setFilterData(dataSoucre);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Process Logs"
            pageTitle="Marketing"
            isSubTitle={false}
          />
          <Card>
            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      <ButttonTravelNinjaz
                        backGroundColor="primary"
                        className="add-btn me-1 my-1"
                        id="create-btn"
                        onCLickHancller={showHideHandller}
                        buttonText={showHide ? "Hide Filters" : "Show Filters"}
                      />
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2 my-1">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search..."
                          onChange={(e) => inputHandller(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col>
                </Row>
                {showHide ? (
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <div className="mt-3">
                        <Label className="form-label mb-0">Date</Label>
                        <Flatpickr
                          className="form-control flatPickrInput"
                          // onChange={([date]) => {
                          //   // console.log(date);
                          //   setFromDate(date);
                          //   setToDate(date);
                          // }}
                          onChange={onChangeDatePickerHandller}
                          // value={selectedDates}
                          options={{
                            mode: "range",
                            dateFormat: "d-M-Y",
                            defaultDate: fromDate,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Table
                  columns={columns}
                  expandedRowRender={expandedRowRender}
                  dataSource={
                    searchText?.length > 0 ? [...filterData] : [...dataSoucre]
                  }
                  locale={{
                    triggerDesc: null,
                    triggerAsc: null,
                    cancelSort: null,
                  }}
                  scroll={{
                    // x: 1500,
                    y: 340,
                  }}
                  pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      5, 10, 20, 50, 100,
                      // dataSource?.length,
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items  `,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default Processlogs;
