import { url } from "../common/const";
import { get, post, post_common } from "../common/https";
/**This api for get city list*/
export const GetCityList = (params) => {
  const getdata = get(`${url}/api/City/list?` + params);
  return getdata;
};
/**This service for add city list */
export const AddCityList = (data) => post(`${url}/api/City/Add`, data);

/**This service for update city list */
export const UpdateCityList = (data) => post(`${url}/api/City/Update`, data);


export const ImportCityFileData = (data) =>
  post_common(`${url}/api/City/ImportCityFileData`, data);

export const getImportCityList = (params) => {
  const getdata = get(`${url}/api/City/GetImportCityList?` + params);
  return getdata;
};

export const publishImportedCity = (data) =>
post_common(`${url}/api/City/PublishImportedCity`, data);

export const cancelImportedCity = (data) =>
post_common(`${url}/api/City/CancelImportedCity`, data);



