import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  FormFeedback,
} from "reactstrap";
import AuthSlider from "../AuthCarousel";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getUserAndCompany,
  login,
  loginWithSocial,
  saveCompany,
} from "../../services/User/userService";
import SignuporsigninErrorMessage from "../../common/SignuporsigninErrorMessage";
import { appName } from "../../common/applicationName";
import { useGoogleLogin } from "@react-oauth/google";
//import { ReactSession } from "react-client-session";
import { setLocalStorageItem, setSession, setLocalStorageData } from "../../services/common/session";
import LoadingButton from "../../common/LoadingButton";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { errornotify } from "../../components/Common/notification";
import axios from "axios";
import { faceBookAppId, applicationType } from "../../services/common/const";
import { GetCityList } from "../../services/GlobalMasters/cityServices";

//import { MyContext } from '../../MyContext';


const CoverSignIn = () => {
  document.title = `${appName}-Login`;
  const [userLogin, setUserLogin] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //const { text, setText } = useContext(MyContext);
  // const { text2, setText2 } = useContext(MyContext);
//  const { globalShowDestinationType, setGlobalShowDestinationType} = useContext(MyContext);
//  const { globalShowDestination, setGlobalShowDestination  } = useContext(MyContext);
//  const { globalShowCity, setGlobalShowCity } = useContext(MyContext);

//   const { globalDestinationTypeList, setGlobalDestinationTypeList } = useContext(MyContext);
//   const { globalDestinationList, setGlobalDestinationList  } = useContext(MyContext);
//   const { globalCityList, setGlobalCityList } = useContext(MyContext);

//   const { globalDefaultDestinationType, setGlobalDefaultDestinationType } = useContext(MyContext);
//   const { globalDefaultDestination, setGlobalDefaultDestination } = useContext(MyContext);
//   const { globalDefaultCity, setGlobalDefaultCity }  = useContext(MyContext);

//setGlobalDestinationTypeList(JSON.stringify(destinationTypeResult));
//setGlobalShowDestinationType("true");
//setGlobalDefaultDestinationType(0);


  const navigate = useNavigate();
  /**This function for user login */
  const loginUser = async (values) => {
    setIsLoading(true);
    try {
      // Check if User is valid from WebAPI Database

      //Clear all local storage data before starting
      localStorage.clear();

      const user = await login({
        Username: values?.username,
        Password: values?.password,
        Social_media_type: "APPLICATION",
      });


       if (user?.status === "SUCCESS") {
          var promise = setSession("userDetails", user);
          let params = new URLSearchParams();
          params.append("AspNetUserId", user?.userKey);
          const response = await getUserAndCompany(params);

           //const response =  companyResponse?.response;

        if (response?.data?.status === "SUCCESS") {
          setLocalStorageItem("currencySymbol",response?.data?.data?.usercompany?.currencyUnit);


          
          const destinationTypeResult =   response?.data?.data?.usercompany?.companyDestinationTypeList;
          const destinationResult = response?.data?.data?.usercompany?.companyDestinationList;

               
          localStorage.setItem("global_destinations_type_list",  JSON.stringify(destinationTypeResult));
          localStorage.setItem("global_destinations_list",  JSON.stringify(destinationResult));

          params = new URLSearchParams();
          if ((destinationTypeResult != null) &&  (destinationTypeResult?.length > 1) )
          {         
            localStorage.setItem("global_show_destination_type", "true")  //Show to user
            localStorage.setItem("global_default_destination_type_object", ""); //Allow user to select
           
          }
          else if ((destinationTypeResult != null) &&  (destinationTypeResult?.length == 1) )
          {         
            localStorage.setItem("global_show_destination_type", "false") //Hide to user
            localStorage.setItem("global_default_destination_type_object", JSON.stringify(destinationTypeResult?.[0]));      // Defaust selected value 
            params.append("destination_type_id", destinationTypeResult?.[0].destination_type_id);
          }
          else{
            localStorage.setItem("global_show_destination_type", "false")
            localStorage.setItem("global_default_destination_type_object", "");
          }

          if ((destinationResult != null) &&  (destinationResult?.length > 1) )
          {         
            localStorage.setItem("global_show_destination", "true");  //Show to user
            localStorage.setItem("global_default_destination_object", ""); //Allow user to select
          }
          else if ((destinationResult != null) &&  (destinationResult?.length == 1) )
          {
            localStorage.setItem("global_show_destination", "false"); //Hide to user 
            localStorage.setItem("global_default_destination_object", JSON.stringify(destinationResult?.[0])); // Defaust selected value 
            params.append("destination_id", destinationResult?.[0].destination_id);
          }  
          else{
            localStorage.setItem("global_show_destination", "false")
            localStorage.setItem("global_default_destination_object", "");
          }

          debugger;

         // Get All Cities or cities for default destination type and destination   
          const cityList = await GetCityList(params);
          if (cityList?.data?.status === "SUCCESS") {     
            const cityResult = cityList.data.data    ;
            localStorage.setItem("global_city_list",  JSON.stringify(cityResult));
          } 
          localStorage.setItem("global_default_city_object", "");

          promise.then(function () {
            navigate("/dashboard");
          });
        } else {
          setErrorMessage(response?.data?.message);
        }

        //setErrorMessage(undefined);
      } else {
        setErrorMessage(user.message);
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username || "",
      password: userLogin.password || "",
    },
    validationSchema: Yup.object({
      username: Yup.string().email().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: (values) => {
      loginUser(values);
    },
  });
  /**login with google */
  const loginWithgoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
            },
          }
        );
        const {
          sub,
          name,
          given_name,
          family_name,
          picture,
          email,
          email_verified,
          local,
        } = response.data;
        const responseLogin = await loginWithSocial({
          Email: email,
          Name: name,
          Email_verified: email_verified,
          Role: "Admin",
          FirstName: given_name,
          LastName: family_name,
          Operation: "ADD",
          Social_media_type: "GOOGLE",
          ApplicationType: applicationType,
        });
        if (responseLogin?.status === "SUCCESS") {
          const responseCompany = await saveCompany({
            Company_name: "",
            Company_gst_no: "",
            Operation: "ADD",
            Is_active: true,
            Row_created_by: responseLogin.email,
            Row_altered_by: responseLogin.email,
            AspNetUserId: responseLogin.userKey,
          });
          if (responseCompany?.status === "SUCCESS") {
            let params = new URLSearchParams();
            params.append("AspNetUserId", responseLogin.userKey);
            const response = await getUserAndCompany(params);
            if (response?.data?.status === "SUCCESS") {
              // setCurrencySymbol(response.data.data.usercompany.currencyUnit);
              setLocalStorageItem(
                "currencySymbol",
                response?.data?.data?.usercompany?.currencyUnit
              );
              setSession("userDetails", responseLogin);
              navigate("/");
            } else {
              setErrorMessage(response?.data?.message);
            }
          } else {
            setErrorMessage(responseCompany?.message);
          }
        } else {
          setErrorMessage(responseLogin?.message);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    },
  });
  /**login with facebook */
  const responseFacebook = async (response) => {
    try {
      const { email, first_name, last_name, name } = response;
      const responseLogin = await loginWithSocial({
        Email: email,
        Name: name,
        Email_verified: true /**This type manualy because not get yet resoponsefacebook */,
        Role: "Admin",
        FirstName: first_name,
        LastName: last_name,
        Operation: "ADD",
        Social_media_type: "FACEBOOK",
        ApplicationType: applicationType,
      });
      if (responseLogin.status === "SUCCESS") {
        const responseCompany = await saveCompany({
          Company_name: "",
          Company_gst_no: "",
          Operation: "ADD",
          Is_active: true,
          Row_created_by: responseLogin.email,
          Row_altered_by: responseLogin.email,
          AspNetUserId: responseLogin.userKey,
        });
        if (responseCompany?.status === "SUCCESS") {
          let params = new URLSearchParams();
          params.append("AspNetUserId", responseLogin.userKey);
          const response = await getUserAndCompany(params);
          if (response?.data?.status === "SUCCESS") {
            // setCurrencySymbol(response.data.data.usercompany.currencyUnit);
            setLocalStorageItem(
              "currencySymbol",
              response?.data?.data?.usercompany?.currencyUnit
            );
            setSession("userDetails", responseLogin);
            navigate("/");
          } else {
            setErrorMessage(response.data.message);
          }
        } else {
          setErrorMessage(responseCompany.message);
        }
      } else {
        setErrorMessage(responseLogin.message);
      }
    } catch (error) {
      // console.log(error);
      errornotify(error);
    }
  };
  return (
    <>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>

            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="g-0">
                    <AuthSlider />
                    <Col lg={7}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to TravelNinjaz B2B.
                          </p>
                        </div>

                        <div className="mt-4">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <div className="mb-3">
                              <Label htmlFor="username" className="form-label">
                                Username
                              </Label>
                              <Input
                                type="email"
                                name="username"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                  validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                              validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="float-end">
                                <Link
                                  to="/forgotpassword"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  type={passwordShow ? "text" : "password"}
                                  name="password"
                                  className="form-control pe-5"
                                  placeholder="Enter password"
                                  id="password-input"
                                  value={validation.values.password || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                  type="button"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                            </div>

                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="auth-remember-check"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                Remember me
                              </Label>
                            </div>

                            <div className="mt-4">
                              {isLoading ? (
                                <LoadingButton
                                  btnText={"Sign In"}
                                  externalClass={"success w-100"}
                                  color={"success"}
                                />
                              ) : (
                                <Button
                                  color="success"
                                  className="w-100"
                                  type="submit"
                                >
                                  Sign In
                                </Button>
                              )}
                            </div>
                            {errorMessage !== undefined ? (
                              <SignuporsigninErrorMessage
                                message={errorMessage}
                              />
                            ) : (
                              ""
                            )}
                            <div className="mt-4 text-center">
                              <div className="signin-other-title">
                                <h5 className="fs-13 mb-4 title">
                                  Sign In with
                                </h5>
                              </div>

                              <div>
                                {/* <Button
                                  color="primary"
                                  className="btn-icon me-1"
                                >
                                  <i className="ri-facebook-fill fs-16"></i>
                                </Button> */}
                                <FacebookLogin
                                  appId={faceBookAppId}
                                  autoLoad={false}
                                  fields="name,email,picture,first_name, last_name"
                                  callback={responseFacebook}
                                  cssClass="my-facebook-button-class"
                                  icon="fa-facebook facebookIconStyle"
                                  render={(renderProps) => (
                                    <Button
                                      color="primary"
                                      className="btn-icon me-1"
                                      onClick={renderProps.onClick}
                                    >
                                      <i className="ri-facebook-fill fs-16"></i>
                                    </Button>
                                  )}
                                />
                                <Button
                                  color="danger"
                                  className="btn-icon me-1"
                                  onClick={() => loginWithgoogle()}
                                >
                                  <i className="ri-google-fill fs-16"></i>
                                </Button>
                                {/* <Button
                                  color="danger"
                                  className="btn-icon me-1"
                                >
                                  <i className="ri-google-fill fs-16"></i>
                                </Button> */}
                                {/* <Button color="dark" className="btn-icon me-1">
                                  <i className="ri-github-fill fs-16"></i>
                                </Button>
                                <Button color="info" className="btn-icon">
                                  <i className="ri-twitter-fill fs-16"></i>
                                </Button> */}
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <Link
                              to="/signup"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Signup
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} TravelNinjaz B2B. Crafted
                    with <i className="mdi mdi-heart text-danger"></i> by &nbsp;
                    <b>THE</b>
                    Digitalogic
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </>
  );
};

export default CoverSignIn;
