import { url } from "../common/const";
import { get, post, post_common  } from "../common/https";

/**This api for get City SiteSeeing  list*/
export const getCitySiteSeeingList = (params) => {
  const getdata = get(`${url}/api/CitySiteSeeing/list?` + params);
  return getdata;
};
/**This api for save City SiteSeeing list*/
export const saveCitySiteSeeingList = (data) =>
  post(`${url}/api/CitySiteSeeing/save`, data);

  export const importCitySiteSeeingFileData = (data) =>
  post_common(`${url}/api/CitySiteSeeing/ImportCitySiteSeeingFileData`, data);

export const getImportCitySiteSeeingList = (params) => {
  const getdata = get(`${url}/api/CitySiteSeeing/GetImportCitySiteSeeingList?` + params);
  return getdata;
};

export const publishImportedCitySiteSeeing = (data) =>
post_common(`${url}/api/CitySiteSeeing/PublishImportedCitySiteSeeing`, data);

export const cancelImportedCitySiteSeeing = (data) =>
post_common(`${url}/api/CitySiteSeeing/CancelImportedCitySiteSeeing`, data);




