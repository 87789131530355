import React, { useEffect, useState } from "react";
import { Col, Form, FormFeedback, Row } from "reactstrap";
import InputProfie from "./InputProfie";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getUserAndCompany, updateUser } from "../../services/User/userService";
import {
  getSessionUsingSessionStorage,
  setSession,
} from "../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import { ToastContainer } from "react-toastify";
import LoadingButton from "../../common/LoadingButton";
const PersonalDetails = ({ userAlldetails, getuserAndCompanyDetails }) => {
  const [userDetails, setUserDetails] = useState();
  const [loading, setIsloading] = useState(false);
  useEffect(() => {
    const promise = getSessionUsingSessionStorage();
    /**check authentication */
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserDetails(value);
        return value;
      });
  }, []);
  const updatePersonalDetails = async (value) => {
    setIsloading(true);
    try {
      const response = await updateUser({
        FirstName: value.firstname,
        LastName: value.lastname,
        Phone: value.contactnumber.toString(),
        Email: userDetails.email,
        Operation: "UPDATE",
        Profession: value.profession,
        City: value.city,
        State: value.state,
        Country: value.country,
        Zipcode: value.zipcode,
        Profile_image: userAlldetails.profile_image,
        UserId: userAlldetails.userId,
        AspNetUserId: userAlldetails.aspNetUserId,
      });
      if (response?.status === "SUCCESS") {
        setSession("userDetails", {
          ...userDetails,
          fullName: `${value.firstname} ${value.lastname}`,
          phoneNumber: value.contactnumber.toString(),
          firstName: value.firstname,
          lastName: value.lastname,
        });
        successNotify("Personal details updated successfully!");
        getuserAndCompanyDetails();
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify("Something went error updation Personal details!");
    } finally {
      setIsloading(false);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: userAlldetails?.firstName || "",
      lastname: userAlldetails?.lastName || "",
      contactnumber: userAlldetails?.phone || "",
      email: userDetails?.email || "",
      profession: userAlldetails?.profession || "",
      city: userAlldetails?.city || "",
      state: userAlldetails?.state || "",
      country: userAlldetails?.country || "",
      zipcode: userAlldetails?.zipcode || 0,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      contactnumber: Yup.string().required("Please Enter Your Contact Number"),
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      updatePersonalDetails(values);
    },
  });
  return (
    <>
      <ToastContainer />
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"firstname"}
                labelName={"First Name"}
                placeholder={"Enter your first name"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.firstname}
                defaultValue={validation.values.firstname}
                invalid={
                  validation.touched.firstname && validation.errors.firstname
                }
              />
              {validation.errors.firstname && validation.touched.firstname ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstname}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"lastname"}
                labelName={"Last Name"}
                placeholder={"Enter your last name"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.lastname}
                defaultValue={validation.values.lastname}
                invalid={
                  validation.touched.lastname && validation.errors.lastname
                }
              />
              {validation.errors.lastname && validation.touched.lastname ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastname}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"contactnumber"}
                labelName={"Contact Number"}
                placeholder={"Enter your contact number"}
                type={"number"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.contactnumber}
                defaultValue={validation.values.contactnumber}
                invalid={
                  validation.touched.contactnumber &&
                  validation.errors.contactnumber
                }
              />
              {validation.errors.contactnumber &&
              validation.touched.contactnumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.contactnumber}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"email"}
                labelName={"Email"}
                placeholder={"Enter your email"}
                type={"email"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.email}
                defaultValue={validation.values.email}
                invalid={validation.touched.email && validation.errors.email}
                disabled={true}
              />
              {validation.errors.email && validation.touched.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <InputProfie
                id={"profession"}
                labelName={"Profession"}
                placeholder={"Enter your profession"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.profession}
                defaultValue={validation.values.profession}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"city"}
                labelName={"City"}
                placeholder={"Enter your city"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.city}
                defaultValue={validation.values.city}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"state"}
                labelName={"State"}
                placeholder={"Enter your state"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.state}
                defaultValue={validation.values.state}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"country"}
                labelName={"Country"}
                placeholder={"Enter your country"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.country}
                defaultValue={validation.values.country}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"zipcode"}
                labelName={"Zip Code"}
                placeholder={"Enter your zip code"}
                type={"number"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.zipcode}
                defaultValue={validation.values.zipcode}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              {loading ? (
                <LoadingButton
                  btnText={"Update Personal details"}
                  externalClass={"btn btn-primary"}
                  color={"primary"}
                />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Update Personal details
                </button>
              )}
              <button
                type="reset"
                onClick={validation.handleReset}
                className="btn btn-soft-success"
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PersonalDetails;
