import React, { useEffect, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Bar } from "react-chartjs-2";
import { GetDashboardChartData } from "../../services/Dashboard/dashboarServices";
import { errornotify } from "../../components/Common/notification";
import { ToastContainer } from "react-toastify";
const Dashboard = () => {
  const [destinationData, setDestinationData] = useState([]);
  const date = new Date();
  const [dateMonth, setDateMonth] = useState(
    `${(date.getMonth() + 1).toString().padStart(2, "0")}${date.getFullYear()}`
  );
  const [showInputDate, setShowInputDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const onDateChangeHandller = (e) => {
    let valueName = e.target.value;
    const selectDate = new Date(valueName);
    setShowInputDate(selectDate.toISOString().split("T")[0]);
    setDateMonth(
      `${(selectDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}${selectDate.getFullYear()}`
    );
  };
  const getRechartDataFunc = async () => {
    try {
      let params = new URLSearchParams();
      params.append("monthYear", dateMonth);
      const resposne = await GetDashboardChartData(params);
      if (resposne?.data?.message === "SUCCESS") {
        setDestinationData(resposne?.data?.data);
      } else {
        errornotify(resposne?.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };

  useEffect(() => {
    if (dateMonth) {
      getRechartDataFunc();
    }
  }, [dateMonth]);

  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Sales Analytics",
        // backgroundColor: barChartColor[0],
        // borderColor: barChartColor[0],
        borderWidth: 1,
        // hoverBackgroundColor: barChartColor[1],
        // hoverBorderColor: barChartColor[1],
        data: [65, 59, 81, 45, 56, 80, 50, 20],
      },
    ],
  };
  const option = {
    x: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    y: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Dashboards" />
          {/* <Row>
            <Col lg={3}>
              <div>
                <Label htmlFor="exampleInputdate" className="form-label">
                  Select Month
                </Label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleInputdate"
                  value={showInputDate}
                  defaultValue={new Date()}
                  onChange={onDateChangeHandller}
                />
              </div>
            </Col>
          </Row> */}
          <Row>
            {console.log("destinationData", destinationData)}
            <Col lg={6}>
              {/* <DestinationChart
                barData={destinationData}
                color={"#2B4257"}
                dataKey={"destination_name"}
                xaxisName={"number_of_quotation"}
              /> */}
              {/* <Bar width={723} height={320} data={data} options={option} /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
