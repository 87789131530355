import React, {useState, useEffect} from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
} from "reactstrap";
import { destinationTypes } from "../../constants/destinationTypes";
import ButttonTravelNinjaz from "./ButttonTravelNinjaz";
import SearchableSelectOption from "./SearchableSelectOption";

const PopupModal = ({
  tog_scroll,
  headerName,
  isOpen,
  size,
  scrollable,
  handleAddCitySeeing,
  siteAndRateList,
  onChangeHandller,
  showInvalid,
  readOnly,
  removeSiteHandller,
  is_active,
  modalSaveHandller,
  cancelHandller,
  destinationOptions,
  cityOptions,
  destinationType,
  destination,
  onChange,
  city_id,
  city_name
}) => {
  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [showDestinationTypeSelectList, setShowDestinationTypeSelectList] = React.useState(localStorage.getItem("global_show_destination_type") =="true" ? true: false);
 const [showDestinationSelectList, setShowDestinationSelectList] = React.useState(localStorage.getItem("global_show_destination") =="true" ? true: false);
 const [cityList, setCityList] =  useState( JSON.parse(localStorage.getItem("global_city_list")));

   /**This useEffect for calling get api*/
   useEffect(() => {
 
    if (cityList?.length > 0) {
      const activeCityList = cityList.filter(item => item.is_active === true);
      activeCityList.map((item, index) => {
         cityOptions.push({
            label: item.city_name,
            value: item.city_id,
          });
        });
      }

}, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={tog_scroll}
        centered
        size={size}
        scrollable={scrollable}
      >
        <ModalHeader className="bg-light p-3">{headerName}</ModalHeader>
        <ModalBody>
          <form>
            <div className="row g-3">

                
            {(showDestinationTypeSelectList == "true" || showDestinationTypeSelectList == true )? (

                  <Col xxl={4}>

                  <SearchableSelectOption
                    labelName={"Destination Type"}
                    options={destinationTypes}
                    value={destinationType}
                    isDisabled={readOnly}
                    onChange={onChange}
                    name={"destinationType"}
                    id={"destinationType"}
                  />
                  </Col>
                    ) :  <>  </>
                    }

               {(showDestinationSelectList == "true" || showDestinationSelectList == true )? (

              <Col xxl={4}>
              <SearchableSelectOption
                labelName={"Destination"}
                options={destinationOptions}
                value={destination}
                isDisabled={readOnly}
                onChange={onChange}
                name={"destination"}
                id={"destination"}
              />
              </Col>
              ) :  <>  </>
              }


             
              
              <Col xxl={4}>
                {/* <SearchableSelectOption
                  labelName={"City"}
                  options={cityOptions}
                  value={city}
                  isDisabled={readOnly}
                  onChange={onChange}
                  name={"city"}
                  id={"city"}
                  showInvalid={showInvalid}
                /> */}

                  <label htmlFor="city_name" className="form-label">
                   City Name
                  </label>

                  <Input
                    type="text"
                    className="form-control"
                    name={"city_name"}
                    id={"city_name"}
                    value={city_name}
                    defaultValue={city_name}
                    onChange={(e) => onChangeHandller(e)}
                    invalid={
                      city_name < 1 && showInvalid
                    }
                    // readOnly={readOnly}
                    required
                  />

                {!city_name && showInvalid && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginLeft: "5px",
                    }}
                  >
                    {" "}
                    Please select City
                  </p>
                )}
              </Col>
              <hr />
              <Col xxl={12}>
                <div>
                  <label htmlFor="add_city" className="form-label">
                    Site Name And Rate{" "}
                    <i
                      className="ri-add-line align-bottom mx-2"
                      onClick={handleAddCitySeeing}
                      id="add_city"
                      style={{
                        padding: "3px",
                        // marginTop: "10px",
                        fontSize: "14px",
                        borderRadius: "50%",
                        backgroundColor: "#099885",
                        color: "white",
                        cursor: "pointer",
                      }}
                    ></i>
                    <Tooltip
                      isOpen={tooltipOpen}
                      placement="right"
                      target="add_city"
                      toggle={() => {
                        setTooltipOpen(!tooltipOpen);
                      }}
                    >
                      Add Site and Rate
                    </Tooltip>
                  </label>
                </div>
              </Col>
              <div>
                {/**Child Table */}
                <div className="table-responsive table-card">
                  <table
                    className="table align-middle table-nowrap"
                    id="customerTable"
                  >
                    <thead className="table-light">
                      <tr>
                        <th width={10} className="">S.No.</th>
                        <th width={350} className="">Site Name</th>
                        <th width={100} className="">Private Rate</th>
                        <th width={100} className="">SIC Rate</th>
                        <th width={50} className="">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {" "}
                      {siteAndRateList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td >
                              <Input
                                type="text"
                                className="form-control"
                                name="site"
                                id={index + 1}
                                value={item.site}
                                defaultValue={item.site}
                                onChange={(e) => onChangeHandller(e)}
                                invalid={!item.site && showInvalid}
                                required
                              />
                              {!item.site && showInvalid ? (
                                <p style={{ fontSize: "12px", color: "red" }}>
                                  Site is Required
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td>
                              <Input
                                type="number"
                                className="form-control"
                                name="rate"
                                id={index + 1}
                                value={item.rate}
                                defaultValue={item.rate}
                                onChange={(e) => onChangeHandller(e)}
                                invalid={!item.rate && showInvalid}
                                required
                              />
                              {!item.rate && showInvalid ? (
                                <p style={{ fontSize: "12px", color: "red" }}>
                                 Private Rate is Required
                                </p>
                              ) : (
                                ""
                              )}
                            </td> */}

                            <td>
                              <Input
                                type="number"
                                className="form-control"
                                name="private_rate"
                                id={index + 1}
                                value={item.private_rate == 0 ? "": item.private_rate }
                                defaultValue={item.private_rate == 0 ? "": item.private_rate }
                                onChange={(e) => onChangeHandller(e)}
                                invalid={!item.private_rate && showInvalid}
                                required
                              />
                              {!item.private_rate && showInvalid ? (
                                <p style={{ fontSize: "12px", color: "red" }}>
                                 Private Rate is Required
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <Input
                                type="number"
                                className="form-control"
                                name="sic_rate"
                                id={index + 1}
                                value={item.sic_rate == 0 ? "": item.sic_rate }
                                defaultValue={item.sic_rate}
                                onChange={(e) => onChangeHandller(e)}
                                invalid={!item.sic_rate && showInvalid}
                              />
                              {!item.sic_rate && showInvalid ? (
                                <p style={{ fontSize: "12px", color: "red" }}>
                                 SIC Rate is Required
                                </p>
                              ) : (
                                ""
                              )}

                            </td>
                            <td>
                              <i
                                className="ri-close-line"
                                onClick={() => removeSiteHandller(item.key)}
                                style={{ fontSize: "25px", cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="form-check form-switch form-switch-success my-3">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="is_active"
                id="SwitchCheck3"
                onChange={(e) => onChangeHandller(e)}
                defaultChecked={is_active}
              />
              <Label className="form-check-label" htmlFor="SwitchCheck3">
                Is Active
              </Label>
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div>
      </Modal>
    </>
  );
};

export default PopupModal;
