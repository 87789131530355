import React, { useEffect, useContext} from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import ColumnVisibleAndHide from "../../../components/Common/GloablMaster/columnvisibleAndHide/ColumnVisibleAndHide";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import Export from "../../../components/Common/GloablMaster/export/Export";
import Mastersearch from "../../../components/Common/GloablMaster/Mastersearch";
import { Table } from "antd";
import PopupModal from "../../../components/Common/GloablMaster/PopupModal";
import { GetDestinationList } from "../../../services/GlobalMasters/destinationService";
import { GetCityList } from "../../../services/GlobalMasters/cityServices";
import {
  errornotify,
  successNotify,
} from "../../../components/Common/notification";
import {
  getCitySiteSeeingList,
  saveCitySiteSeeingList,
} from "../../../services/GlobalMasters/citySiteSeeingService";
import { destinationTypesFilter } from "../../../components/constants/destinationTypes";
import {
  downloadExcel,
  filterUniqueCity,
  filterUniqueDestination,
} from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import {
  getLocalStorageItem,
  getSessionUsingSessionStorage,
} from "../../../services/common/session";
import { getUserAndCompany } from "../../../services/User/userService";
import CurrencyShow from "../../../common/CurrencyShow";
import { useNavigate } from "react-router-dom";
import { authError } from "../../../services/common/const";
import UseAuth from "../../../components/UseAuth";

const CitySiteSeeing = () => {
  UseAuth();

  // document.title = "CitySiteSeeing | TravelNinjaz B2B";
  document.title = `${appName}-CitySiteSeeing`;
  const [userName, setUserName] = useState();
  const [userDetails, setUserDetails] = useState(undefined);
  const currencySymbol = localStorage.getItem("currencySymbol");
  const navigate = useNavigate();
  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
        setUserDetails(value);
      });
  }, []);

  const [exportTableData, setExportableData] = useState([]);
  const [exportedData, setExportedData] = useState([]);
  const [switchCheck, setSwitchCheck] = useState({
    destinationType: true,
    createdBy: true,
    createdDate: true,
    modifiedBy: true,
    modifiedDate: true,
  });
  const [modal_scroll, setmodal_scroll] = useState(false);
  /**Filter column wise  */
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  const [filterColumnWiseCity, setFilterColunmWiseCity] = useState([]);
  /**input change handller*/
  const onChangeHandller = (e) => {

      if (e.target.name === "is_active") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.checked });
    } 
    if (e.target.name === "city_name") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.value });
    }
    else if (

      e.target.name === "destinationType" ||
      e.target.name === "createdBy" ||
      e.target.name === "createdDate" ||
      e.target.name === "modifiedBy" ||
      e.target.name === "modifiedDate"
    ) {
      setSwitchCheck({ ...switchCheck, [e.target.name]: !e.target.checked });
    } else {
      const newArray = siteAndRateList.map((item, i) => {
        if (parseInt(e.target.id) === item.key) {
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        } else {
          return item;
        }
      });
      setSiteAndRateList(newArray);
    }
  };
  /**This is for children data*/
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Site",
        dataIndex: "site",
      },
      {
        title: "Private Rate",
        dataIndex: "private_rate",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record.private_rate}
              currencySymbol={currencySymbol}
            />
          );
        },
      },
      {
        title: "SIC Rate",
        dataIndex: "sic_rate",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record.sic_rate}
              currencySymbol={currencySymbol}
            />
          );
        },
      }
    ];
    return (
      <Table
        columns={columns}
        dataSource={row.siteSeeingList}
        pagination={false}
        className="m-3"
        scroll={{
          y: 300,
        }}
      />
    );
  };
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [showInvalid, setShowInvalid] = useState(false);
  /*Original Api Data**/
  const [dataSource, setDataSource] = useState({
    destinationList: [],
    cityList: [],
    citySiteSeeingList: [],
  });
  let { destinationList, cityList, citySiteSeeingList } = dataSource;
  /**This is state for select options*/
  const [selectOptions, setSelectOptions] = useState({
    destinationOptions: [],
    cityOptions: [],
  });

  /**This state for read Only and edit only*/
  const [readOnly, setReadOnly] = useState(false);
  let { destinationOptions, cityOptions } = selectOptions;

  /**City Site Name And Value Array*/
  const [siteAndRateList, setSiteAndRateList] = useState([
    {
      key: 1,
      site: null,
      private_rate: null,
      sic_rate: null,
      //Child_rate: null
    },
  ]);

  /**selection state */
  const [modalValue, setModalValue] = useState({
    destinationType: null,
    destination: null,
    city_id: null,
    city_name: null,
    is_active: true,
    operation: "",
  });
  const { destinationType, destination, city_id, city_name, is_active } = modalValue;

  /**This function for delete city*/
  const removeSiteHandller = (id) => {
    if (siteAndRateList?.length > 1) {
      const filterSite = siteAndRateList.filter((item) => {
        return item.key !== id;
      });
      setSiteAndRateList(filterSite);
    } else {
      errornotify("Please add atleast one Site.");
    }
  };

  /**Over All table search in master */
  const searchInputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = citySiteSeeingList.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(citySiteSeeingList);
      setExportableData(JSON.parse(JSON.stringify(citySiteSeeingList)));
    }
  };

  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(citySiteSeeingList)));
  }, [
    citySiteSeeingList,
    switchCheck.destinationType,
    switchCheck.createdBy,
    switchCheck.createdDate,
    switchCheck.modifiedBy,
    switchCheck.modifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(citySiteSeeingList)));
    }
  }, [searchText]);
  /**Package Save*/
  const modalSaveHandller = async () => {
    try {
      setShowInvalid(true);
      if (!city_name) {
        throw "City is required.";
      }
      if (siteAndRateList.length > 0) {
        // const citySiteSeeingList = siteAndRateList.map((item, index) => {
        //   if (!item.site) {
        //     throw "Site is required.";
        //   }
        //   if (!item.rate) {
        //     throw "Rate is required.";
        //   }
        //   return {
        //     City_id: city?.value,
        //     Site: item.site,
        //     Rate: parseInt(item.rate),
        //     Is_active: is_active,
        //     Row_created_by: userName,
        //     Row_created_date: new Date(),
        //     Row_altered_by: userName,
        //     Row_altered_date: new Date(),
        //   };
        // });

        debugger;
        const updatableData = {
          Destination_type_id: modalValue?.destinationType,
          Destination_id: modalValue?.destination,
          // City_id: city?.value,
          City_id: city_id,
          City_name: city_name,
          SiteSeeingList: siteAndRateList,
          Is_active: is_active,
          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
          operation: modalValue?.operation
        };

        const response = await saveCitySiteSeeingList(updatableData);
        if (response?.status === "SUCCESS") {

  
          await getAllList(1);
          
          successNotify(response?.message);
          tog_scroll();
          setShowInvalid(false);
          setSiteAndRateList([
            {
              key: 1,
              site: null,
             // rate: null,
              private_rate: null,
              sic_rate: null,
              //Child_rate: null,
            },
          ]);
          setModalValue({
            destinationType: null,
            destination: null,
            city_id: null,
            city_name: null,
            is_active: is_active,
          });
          setReadOnly(false);
          window.location.reload();
        } else {
          errornotify(response?.message);
        }
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };

  /**This is for destination options*/
  if (destinationList?.length > 0 && destinationType?.value) {
    destinationOptions = [];
    const filterDestinationList = destinationList.filter((item) => {
      return (
        item.destination_type_id === destinationType?.value && item.is_active
      );
    });
    filterDestinationList.map((item, index) => {
      destinationOptions.push({
        label: item.destination_name,
        value: item.destination_id,
      });
    });
  }

  // /**This is option for city */
  // if (cityList?.length > 0 && destination?.value) {
  //   cityOptions = [];
  //   cityList
  //     .filter((item) => {
  //       return item.destination_id === destination?.value && item.is_active;
  //     })
  //     .map((item, index) => {
  //       cityOptions.push({
  //         label: item.city_name,
  //         value: item.city_id,
  //       });
  //     });
  // }

  /**This function for select option handller*/

  const slectOptionOnChange = (chooseOption, name, id) => {
    if (name === "destinationType") {
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          destination: null,
          city_id: null,
          city_name: null
        };
      });
      setSiteAndRateList([
        {
          key: 1,
          site: null,
         // rate: null,
         private_rate: null,
         sic_rate: null,
        // child_rate: null,
        },
      ]);
    } else if (name === "destination") {
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          city_id: null,
          city_name: null
        };
      });
      setSiteAndRateList([
        {
          key: 1,
          site: null,
          //rate: null,
          private_rate: null,
          sic_rate: null,
          //child_rate: null,
        },
      ]);
    }
    setModalValue((prevValue) => {
      return {
        ...prevValue,
        [name]: chooseOption,
      };
    });
  };

  /**This is for cancel handller */
  const cancelHandller = () => {
    tog_scroll();
    setReadOnly(false);
  };
  const getAllList = async (refreshLocalStrageList) => {
    try {
      /**destination get api call */

      debugger;
      let params = new URLSearchParams();

      let global_default_destination_type_object;
      if(localStorage.getItem("global_default_destination_type_object") != "")
      {
        global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
        params.append("destination_type_id",global_default_destination_type_object.destination_type_id);
      }
          
      let global_default_destination_object;
      if(localStorage.getItem("global_default_destination_object") != "")
      { 
        global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
        params.append("destination_id",global_default_destination_object.destination_id);
      } 

      let global_default_city_object;
      if(localStorage.getItem("global_default_city_object") != "" && localStorage.getItem("global_default_city_object") != "null")
      { 
        global_default_city_object = JSON.parse(localStorage.getItem("global_default_city_object") ?? "");
        params.append("city_id",global_default_city_object.city_id);
      } 

   
      // const destinationResult = await GetDestinationList(params);
      // if (destinationResult?.data?.status === "SUCCESS") {
      //   setDataSource((prevDataSource) => {
      //     return {
      //       ...prevDataSource,
      //       destinationList: destinationResult.data.data,
      //     };
      //   });
      // } else {
      //   throw destinationResult.message;
      // }     

      /**city get api call **/
         
           //Set Default model
           debugger;
      setModalValue({
        ...modalValue,
        destinationType:global_default_destination_type_object?.destination_type_id,
        destination: global_default_destination_object?.destination_id,
        city_id: global_default_city_object?.city_id,
        city_name: global_default_city_object?.city_name,

      });


      const cityResult = await GetCityList(params);
      if (cityResult?.data?.status === "SUCCESS") {
        setDataSource((prevDataSource) => {
          return {
            ...prevDataSource,
            cityList: cityResult.data.data,
          };
        });

        debugger;

        if(refreshLocalStrageList == 1)
        {
          localStorage.setItem("global_city_list",  JSON.stringify(cityResult.data.data));
        }

      } else {
        throw cityResult?.message;
      }

      /**City Site seeing get api call**/
     
debugger;

      const citySiteSeeingResult = await getCitySiteSeeingList(params);
      if (citySiteSeeingResult?.data?.status === "SUCCESS") {
        setDataSource((prevDataSource) => {
          return {
            ...prevDataSource,
            citySiteSeeingList: citySiteSeeingResult.data.data,
          };
        });

        const uniqueDestination = filterUniqueDestination(
          citySiteSeeingResult.data.data
        );
        let filterDestination = [];
        uniqueDestination?.map((item, index) => {
          filterDestination.push({
            text: item?.destination_name,
            value: item?.destination_id,
          });
        });
        setFilterColumnWiseDestination(filterDestination);
        const uniqueCity = filterUniqueCity(citySiteSeeingResult.data.data);
        let filterCity = [];
        uniqueCity?.map((item, index) => {
          filterCity.push({
            text: item?.city_name,
            value: item?.city_id,
          });
        });
        setFilterColunmWiseCity(filterCity);
      } else {
        //throw citySiteSeeingResult?.message;
        errornotify(citySiteSeeingResult.data.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };

  /**This useEffect for calling get api*/
  useEffect(() => {
    getAllList();
  }, []);

  /**This is Site And Rate list handller*/
  const handleAddCitySeeing = () => {
    setSiteAndRateList([
      ...siteAndRateList,
      { key: siteAndRateList?.length + 1,
        site: null, 
        //rate: null 
        private_rate: null,
        sic_rate: null,
       // child_rate: null,
      },
    ]);
  };


  /***Edit handller*/
  const editHandller = (record) => {
    debugger;
    console.log(record.is_active);
    setReadOnly(true);
    // setModalValue({
    //   ...modalValue,
    //   destinationType:
    //     record.destination_type_id === 1
    //       ? { label: "Domestic", value: 1 }
    //       : { label: "International", value: 2 },
    //   destination: {
    //     label: record.destination_name,
    //     value: record.destination_id,
    //   },
    //   city: {
    //     label: record.city_name,
    //     value: record.city_id,
    //   },
    //   is_active: record.is_active,
    // });
    setModalValue({
      ...modalValue,
      destinationType: record.destination_type_id ,
      destination: record.destination_id,
      // city: {
      //   label: record.city_name,
      //   value: record.city_id,
      // },
      city_id: record.city_id,
      city_name: record.city_name,
      is_active: record.is_active,
      operation:"UPDATE"
    });

    setSiteAndRateList([...record.siteSeeingList]);
    tog_scroll();
  };
  /** Columns of table*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },   
    {
      title: "City",
      dataIndex: "city_name",
      width: 140,
      defaultSortOrder: "ascend",
      // ...getColumnSearchProps("city_name"),
      filters: filterColumnWiseCity,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.city_name.localeCompare(b.city_name),
        multiple: 6,
      },
      // sorter: (a, b) => a.city_name.localeCompare(b.city_name),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: switchCheck.createdBy,
    },
    {
      title: "Created On",
      dataIndex: "row_created_date",
      width: 160,
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: switchCheck.createdDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: switchCheck.modifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: switchCheck.modifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);
  /**This state for a modal open and false */
  const [add_modal_is_open, set_add_modal_is_open] = useState(false);
  /**This function for a modal close and open */
  const tog_scroll = () => {
    set_add_modal_is_open(!add_modal_is_open);
  };
  /**This function for add button handller */
  const AddHandler = () => {

      /**This is option for city */
  // if (cityList?.length > 0 && destination?.value) {
  //   cityOptions = [];
  //   cityList
  //     .filter((item) => {
  //       return item.destination_id === destination?.value && item.is_active;
  //     })
  //     .map((item, index) => {
  //       cityOptions.push({
  //         label: item.city_name,
  //         value: item.city_id,
  //       });
  //     });
  // }
  setModalValue({ ...modalValue, city_id: 0, city_name: "", operation:"ADD"});
 // setSiteAndRateList([]);

  setSiteAndRateList([
    { key: 1,
      site: null, 
      private_rate: null,
      sic_rate: null,
    },
  ]);

  

    setReadOnly(false);
    tog_scroll();
  };

  /**set exported Data*/
  let exportArray = [];
  /** Export data*/
  useEffect(() => {
    setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        elementParent?.citySiteSeeingList?.forEach((element) =>
          exportArray.push({
            destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination: elementParent.destination_name,
            City: elementParent.city_name,
            Site: element.site,

            // Rate: element.rate,
            private_rate: element.private_rate,
            sic_rate: element.sic_rate,
           // child_rate: element.child_rate,
      
            ...(!switchCheck.createdBy && {
              RowCreatedBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              RowCreatedDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              RowModifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              RowModifiedDate: elementParent.row_altered_date,
            }),
          })
        );
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);

  const ImportCitySiteSeeing = () => {
    navigate('../ImportCitySiteSeeing', {replace: true});
  };

  return (
    <>
      {" "}
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="City & Site Seeings"
            isSubTitle={true}
            pageTitle="Setup"
          />

          
<div>
      {/* <button onClick={() => setText('Hello, world!')}>
        Click me
      </button> */}
    </div>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          {/**Export data */}
                          <Export
                            downloadExcel={downloadExcel}
                            exportedData={exportedData}
                            name="CitySiteSeeing"
                          />

                          <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              ImportCitySiteSeeing()
                            }                            
                            buttonText="Import"                            
                          />

                          {/**Hide and show column*/}
                          <ColumnVisibleAndHide
                            changeHandller={onChangeHandller}
                            switchCheck={switchCheck}
                          />
                        </div>
                      </Col>
                      {/**search filed in grid global */}
                      <Mastersearch inputHandller={searchInputHandller} />
                    </Row>
                  </div>
                  {/**Table information */}
                  <div>
                    <Table
                      dataSource={
                        searchText.length > 0 ? filterData : citySiteSeeingList
                      }
                      columns={columns}
                      scroll={{
                        y: 350,
                      }}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 50, 100],
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items  `,
                      }}
                      expandedRowRender={expandedRowRender}
                      bordered
                      locale={{
                        triggerDesc: null,
                        triggerAsc: null,
                        cancelSort: null,
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/**Modal */}
      <PopupModal
        tog_scroll={tog_scroll}
        headerName={`${!readOnly ? "Add" : "Edit"} - City & Site Seeing`}
        isOpen={add_modal_is_open}
        size="xl"  //lg
        scrollable={false}
        handleAddCitySeeing={handleAddCitySeeing}
        siteAndRateList={siteAndRateList}
        destinationOptions={destinationOptions}
        cityOptions={cityOptions}
        city_id={city_id}
        city_name={city_name}
        destination={destination}
        is_active={is_active}
        cancelHandller={cancelHandller}
        destinationType={destinationType}
        modalSaveHandller={modalSaveHandller}
        onChange={slectOptionOnChange}
        onChangeHandller={onChangeHandller}
        readOnly={readOnly}
        removeSiteHandller={removeSiteHandller}
        showInvalid={showInvalid}
      />
    </>
  );
};

export default CitySiteSeeing;
