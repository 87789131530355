import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { getSessionUsingSessionStorage } from "../../services/common/session";

const Navdata = () => {
  const navigate = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [isDestination, setIsDestination] = useState(false);
  const [isHotelMaster, setIsHotelMaster] = useState(false);
  const [isHotel, setIsHotel] = useState(false);
  const [isLogs, setIsLogs] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isglobalMasters, setisGlobalMasters] = useState(false);
  const [isMarketingMenu, setIsMarketingMenu] = useState(false);
  const [isUploadFiles, setIsUploadFiles] = useState(false);
  const [isUpdateFiles, setIsupdateFiles] = useState(false);
  const [isSendingWhatsapp, setIssendingWhatsapp] = useState(false);
  const [isSendingEmail, setIssendingEmail] = useState(false);
  const [isTransport, setIsTransport] = useState(false);
  const [isPackageMaster, setIsPackageMaster] = useState(false);
  const [isSubCustomizePackage, setIsSubCustomizePackage] = useState(false);

  const [CompanyProvidingDestinationTypes, setCompanyProvidingDestinationTypes] = useState([]);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  //destination_types
  //"1", // Domestic
  //"2",  //International     
  //"3"  //Both (Domestic & International)

  const company_destination_types = [
    {
      id: 1,
      label: "Domestic",
      link: "/customize-domestic-package",
      parentId: 2,
    },
    {
      id: 2,
      label: "International",
      link: "/customize-international-package",
      parentId: 2,
    },
  ];

  
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Package") {
      setIsPackage(false);
    }
    if (iscurrentState !== "Hotel") {
      setIsHotel(false);
    }


  //  let promise = getSessionUsingSessionStorage();
  //   promise
  //     .then(function (value) {
  //       return value;
  //     })
  //     .then((value) => {
  //       setUserName(value.userName);
  //     });
    
  }, [iscurrentState, isDashboard, isPackage, isHotel]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "Packages",
      label: "Packages",
      icon: "ri-apps-2-line",
      //link: "/#",
      link: "/packages",
      click: function (e) {
        e.preventDefault();
        setIsPackage(!isPackage);
        setIscurrentState("Packages");
        updateIconSidebar(e);
      },
      stateVariables: isPackage,
      // subItems: [
      //   {
      //     id: 2,
      //     label: "Customize",
      //     // link: "/globalmasters-customize-package",
      //     link: "/#",
      //     parentId: "Package",
      //     isChildItem: true,
      //     stateVariables: isSubCustomizePackage,
      //     click: function (e) {
      //       e.preventDefault();
      //       setIsSubCustomizePackage(!isSubCustomizePackage);
      //     },
      //     childItems: company_destination_types,
      //   },
      // ],
    },
    {
      id: "Hotel",      
      label: "Hotels",
      icon: "ri-pages-line",
      link: "hotelRooms",
      click: function (e) {
        e.preventDefault();
        setIsHotel(!isHotel);
        setIscurrentState("Hotels");
        updateIconSidebar(e);
      },
      stateVariables: isHotel,
    },
    // {
    //   id: "HotelRooms",      
    //   label: "Hotels Rooms",
    //   icon: "ri-pages-line",
    //   link: "hotelRooms",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsHotel(!isHotel);
    //     setIscurrentState("Hotels");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isHotel,
    // },
    // Setup menu starts

    {
      id: "Setup",
      label: "Setup",
      icon: "ri-rocket-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setisGlobalMasters(!isglobalMasters);
        setIscurrentState("Setup");
        updateIconSidebar(e);
      },
      stateVariables: isglobalMasters,
      subItems: [
        {
          id: "destinations",
          label: "Destinations",
          link: "/destinations",
          parentId: "Setup",      
          stateVariables: isDestination,
          click: function (e) {
            e.preventDefault();
            setIsDestination(!isDestination);
          },         
        },
        {
          id: "city",
          label: "Cities (Remove)",
          parentId: "Setup",
          link: "/city",
          stateVariables: isCity,
          click: function (e) {
            e.preventDefault();
            setIsCity(!isCity);
          },      
        }, 
        {
          id: "City_Site_Seeing",
          // label: "City Site Seeing",
          label: "City & Site seeing",
          link: "/globalmasters_city_site_seeing",
          parentId: "Setup",
        },        
        {
          id: "transports",
          label: "Transports",
          link: "/transports",
          parentId: "Setup", 
          stateVariables: isTransport,
          click: function (e) {
            e.preventDefault();
            setIsTransport(!isTransport);
          }
        }, 
        {
          id: 2,
          label: "Transport Rate",
          link: "/transportRate",
          parentId: "transport",
        },               
        // {
        //   id: "import_setup",
        //   label: "Import Setups",
        //   link: "/importSetup",
        //   parentId: "Setup", 
        //   stateVariables: isTransport,
        //   click: function (e) {
        //     e.preventDefault();
        //     setIsTransport(!isTransport);
        //   }      
        // }     
      ],
    },  

    //Setup menu ends

    // {
    //   id: "GlobalMasters",
    //   label: "Masters",
    //   icon: "ri-rocket-line",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setisGlobalMasters(!isglobalMasters);
    //     setIscurrentState("GlobalMasters");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isglobalMasters,
    //   subItems: [
    //     {
    //       id: "destination",
    //       label: "Destination",
    //       link: "/",
    //       parentId: "GlobalMasters",
    //       isChildItem: true,
    //       stateVariables: isDestination,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsDestination(!isDestination);
    //       },
    //       childItems: [
    //         {
    //           id: 2,
    //           label: "Destination",
    //           link: "/globalmasters-destination",
    //           parentId: "destination",
    //         },
    //         {
    //           id: 3,
    //           label: "pickup And Drop",
    //           link: "/globalmasters-pickupdrop",
    //           parentId: "destination",
    //         },
    //       ],
    //     },
    //     {
    //       id: "city",
    //       label: "City Master",
    //       parentId: "GlobalMasters",
    //       link: "/#",
    //       isChildItem: true,
    //       stateVariables: isCity,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsCity(!isCity);
    //       },
    //       childItems: [
    //         {
    //           id: 2,
    //           label: "City",
    //           link: "/globalmasters-city",
    //           parentId: "city",
    //         },
    //         {
    //           id: 3,
    //           label: "City Site Seeing",
    //           link: "/globalmasters_city_site_seeing",
    //           parentId: "city",
    //         },
    //       ],
    //     },
    //     {
    //       id: "hotel",
    //       label: "Hotel Master",
    //       parentId: "GlobalMasters",
    //       link: "/#",
    //       isChildItem: true,
    //       stateVariables: isHotelMaster,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsHotelMaster(!isHotelMaster);
    //       },
    //       childItems: [
    //         {
    //           id: 1,
    //           label: "Hotel",
    //           link: "/globalmasters-hotel",
    //           parentId: "hotel",
    //         },
    //         {
    //           id: 2,
    //           label: "Hotel Rooms",
    //           link: "/globalmasters-hotel-rooms",
    //           parentId: "hotel",
    //         },
    //       ],
    //     },
    //     {
    //       id: "transport",
    //       label: "Transport Master",
    //       link: "#",
    //       parentId: "GlobalMasters",
    //       isChildItem: true,
    //       stateVariables: isTransport,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsTransport(!isTransport);
    //       },
    //       childItems: [
    //         {
    //           id: 1,
    //           label: "Transport",
    //           link: "/globalmasters-transport",
    //           parentId: "transport",
    //         },
    //         {
    //           id: 2,
    //           label: "Transport Rate",
    //           link: "/globalmasters-transport-rate",
    //           parentId: "transport",
    //         },
    //       ],
    //     },       
    //   ],
    // },    
    {
      id: "marketingmenu",
      label: "Marketing",
      icon: "ri-archive-fill",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsMarketingMenu(!isMarketingMenu);
        setIscurrentState("marketingmenu");
        updateIconSidebar(e);
      },
      stateVariables: isMarketingMenu,
      subItems: [
        {
          id: "uploadfiles",
          label: "Upload Files(CSV,XLSX)",
          link: "/uploadfiles",
          parentId: "marketingmenu",
          isChildItem: false,
          stateVariables: isUploadFiles,
          click: function (e) {
            e.preventDefault();
            setIsUploadFiles(!isUploadFiles);
          },
        },
        {
          id: "updatetravellingcompany",
          label: "Update Travelling Company",
          link: "/updatetravellingcompany",
          parentId: "marketingmenu",
          isChildItem: false,
          stateVariables: isUpdateFiles,
          click: function (e) {
            e.preventDefault();
            setIsupdateFiles(!isUpdateFiles);
          },
        },
        {
          id: "sendwhatsapp",
          label: "Send Whatsapp",
          link: "/sendwhatsapp",
          parentId: "marketingmenu",
          isChildItem: false,
          stateVariables: isSendingWhatsapp,
          click: function (e) {
            e.preventDefault();
            setIssendingWhatsapp(!isSendingWhatsapp);
          },
        },
        {
          id: "sendemail",
          label: "Send Email",
          link: "/sendemail",
          parentId: "marketingmenu",
          isChildItem: false,
          stateVariables: isSendingEmail,
          click: function (e) {
            e.preventDefault();
            setIssendingEmail(!isSendingEmail);
          },
        },
      ],
    },
    {
      id: "processlogs",
      label: "Process Logs",
      icon: "ri-error-warning-line",
      link: "processlogs",
      click: function (e) {
        e.preventDefault();
        setIsLogs(!isLogs);
        setIscurrentState("processlogs");
        updateIconSidebar(e);
      },
      stateVariables: isLogs,
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
